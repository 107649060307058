import anime from "animejs";

const AvailScriptsAnimationGroup = (el) => {
  // Matches for touchscreen
  const autoplayOnInView = matchMedia("(pointer:coarse)").matches;
  const state = {
    wght: 400,
    wdth: 100,
  };
  el.style.setProperty(`--wght`, `${state.wght}`);
  el.style.setProperty(`--wdth`, `${state.wdth}`);

  let currentAnime;

  const onPointerEnter = () => {
    if (currentAnime) {
      currentAnime.pause();
    }
    currentAnime = anime({
      targets: state,
      keyframes:
        el.dataset.mode === "wght"
          ? [
              { wght: 400 },
              { wght: 900 },
              { wght: 100 },
              { wght: 400 },
              { wght: 400 },
            ]
          : [
              { wght: 400, wdth: 100 },
              { wght: 900, wdth: 150 },
              { wght: 900, wdth: 50 },
              { wght: 100, wdth: 50 },
              { wght: 400, wdth: 100 },
            ],
      duration: 4000,
      easing: "easeInOutSine",
      loop: true,
      update: () => {
        el.style.setProperty(`--wght`, `${state.wght}`);
        el.style.setProperty(`--wdth`, `${state.wdth}`);
      },
    });
  };
  const onPointerLeave = () => {
    if (currentAnime) {
      currentAnime.pause();
    }
    currentAnime = anime({
      targets: state,
      wght: 400,
      wdth: 100,
      duration: 300,
      easing: "easeInOutSine",
      loop: false,
      update: () => {
        el.style.setProperty(`--wght`, `${state.wght}`);
        el.style.setProperty(`--wdth`, `${state.wdth}`);
      },
    });
  };

  const onInViewChange = (entries) => {
    if (entries[0].isIntersecting) {
      if (autoplayOnInView) {
        onPointerEnter();
      }
    } else {
      if (autoplayOnInView) {
        onPointerLeave();
      }
    }
  };

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });
  inViewObserver.observe(el);

  if (!autoplayOnInView) {
    el.addEventListener("pointerenter", onPointerEnter);
    el.addEventListener("pointerleave", onPointerLeave);
  }
};

export default AvailScriptsAnimationGroup;
