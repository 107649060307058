const _temp0 = require("url:../../animations/character-bee-001.json");
const _temp1 = require("url:../../animations/character-burrito.json");
const _temp2 = require("url:../../animations/character-cowboy-001.json");
const _temp3 = require("url:../../animations/character-cucumber-002.json");
const _temp4 = require("url:../../animations/character-drummer.json");
const _temp5 = require("url:../../animations/character-fireworks-cracker.json");
const _temp6 = require("url:../../animations/character-hotdog-001.json");
const _temp7 = require("url:../../animations/character-magician-mirrored.json");
const _temp8 = require("url:../../animations/character-magician.json");
const _temp9 = require("url:../../animations/character-milkshake.json");
const _temp10 = require("url:../../animations/character-nono.json");
const _temp11 = require("url:../../animations/character-peanut.json");
const _temp12 = require("url:../../animations/character-rabbit.json");
const _temp13 = require("url:../../animations/character-sombrero-roundelbows.json");
const _temp14 = require("url:../../animations/character-sombrero.json");
const _temp15 = require("url:../../animations/character-tadagirl.json");
const _temp16 = require("url:../../animations/contrast-arabic-ain-001.json");
const _temp17 = require("url:../../animations/contrast-arabic-ain-002.json");
const _temp18 = require("url:../../animations/contrast-cyr-de-001.json");
const _temp19 = require("url:../../animations/contrast-cyr-de-002.json");
const _temp20 = require("url:../../animations/contrast-greek-lambda-001.json");
const _temp21 = require("url:../../animations/contrast-hebrew-aleph-001.json");
const _temp22 = require("url:../../animations/contrast-hebrew-aleph-002.json");
const _temp23 = require("url:../../animations/contrast-korean-t-001.json");
const _temp24 = require("url:../../animations/contrast-korean-t-002.json");
const _temp25 = require("url:../../animations/contrast-latin-aa.json");
const _temp26 = require("url:../../animations/contrast-latin-m-001.json");
const _temp27 = require("url:../../animations/contrast-latin-m-002.json");
const _temp28 = require("url:../../animations/contrast-thai-w-001.json");
const _temp29 = require("url:../../animations/contrast-thai-w-002.json");
const _temp30 = require("url:../../animations/features-latin-b-001.json");
const _temp31 = require("url:../../animations/features-latin-gg.json");
const _temp32 = require("url:../../animations/features-latin-mn-001.json");
const _temp33 = require("url:../../animations/flow-arabic-dal-001.json");
const _temp34 = require("url:../../animations/flow-arabic-waw-002.json");
const _temp35 = require("url:../../animations/flow-cyr-be-002.json");
const _temp36 = require("url:../../animations/flow-devanagari-four-001.json");
const _temp37 = require("url:../../animations/flow-devanagari-one-001.json");
const _temp38 = require("url:../../animations/flow-devanagari-tha-001.json");
const _temp39 = require("url:../../animations/flow-hebrew-tsadi-001.json");
const _temp40 = require("url:../../animations/flow-korean-jieut-001.json");
const _temp41 = require("url:../../animations/flow-latin-eight-002.json");
const _temp42 = require("url:../../animations/flow-latin-g-001.json");
const _temp43 = require("url:../../animations/flow-latin-two-001.json");
const _temp44 = require("url:../../animations/flow-thai-sosola-001.json");
const _temp45 = require("url:../../animations/product-caviar.json");
const _temp46 = require("url:../../animations/product-gum.json");
const _temp47 = require("url:../../animations/product-lightningspray.json");
const _temp48 = require("url:../../animations/product-playingcard.json");
const _temp49 = require("url:../../animations/product-popcorn.json");
const _temp50 = require("url:../../animations/product-squigglys.json");
const _temp51 = require("url:../../animations/weight-arabic-ain-004.json");
const _temp52 = require("url:../../animations/weight-cyr-backwardsye-001.json");
const _temp53 = require("url:../../animations/weight-devanagari-dddha-002.json");
const _temp54 = require("url:../../animations/weight-greek-sigma-001.json");
const _temp55 = require("url:../../animations/weight-latin-e-002.json");
const _temp56 = require("url:../../animations/weight-latin-s-006.json");
const _temp57 = require("url:../../animations/weight-latin-uppercase-s-001.json");
const _temp58 = require("url:../../animations/weight-latin-uppercase-s-nohandles-001.json");
const _temp59 = require("url:../../animations/weight-thai-thothong-005.json");
module.exports = {
  "character-bee-001.json": _temp0,
  "character-burrito.json": _temp1,
  "character-cowboy-001.json": _temp2,
  "character-cucumber-002.json": _temp3,
  "character-drummer.json": _temp4,
  "character-fireworks-cracker.json": _temp5,
  "character-hotdog-001.json": _temp6,
  "character-magician-mirrored.json": _temp7,
  "character-magician.json": _temp8,
  "character-milkshake.json": _temp9,
  "character-nono.json": _temp10,
  "character-peanut.json": _temp11,
  "character-rabbit.json": _temp12,
  "character-sombrero-roundelbows.json": _temp13,
  "character-sombrero.json": _temp14,
  "character-tadagirl.json": _temp15,
  "contrast-arabic-ain-001.json": _temp16,
  "contrast-arabic-ain-002.json": _temp17,
  "contrast-cyr-de-001.json": _temp18,
  "contrast-cyr-de-002.json": _temp19,
  "contrast-greek-lambda-001.json": _temp20,
  "contrast-hebrew-aleph-001.json": _temp21,
  "contrast-hebrew-aleph-002.json": _temp22,
  "contrast-korean-t-001.json": _temp23,
  "contrast-korean-t-002.json": _temp24,
  "contrast-latin-aa.json": _temp25,
  "contrast-latin-m-001.json": _temp26,
  "contrast-latin-m-002.json": _temp27,
  "contrast-thai-w-001.json": _temp28,
  "contrast-thai-w-002.json": _temp29,
  "features-latin-b-001.json": _temp30,
  "features-latin-gg.json": _temp31,
  "features-latin-mn-001.json": _temp32,
  "flow-arabic-dal-001.json": _temp33,
  "flow-arabic-waw-002.json": _temp34,
  "flow-cyr-be-002.json": _temp35,
  "flow-devanagari-four-001.json": _temp36,
  "flow-devanagari-one-001.json": _temp37,
  "flow-devanagari-tha-001.json": _temp38,
  "flow-hebrew-tsadi-001.json": _temp39,
  "flow-korean-jieut-001.json": _temp40,
  "flow-latin-eight-002.json": _temp41,
  "flow-latin-g-001.json": _temp42,
  "flow-latin-two-001.json": _temp43,
  "flow-thai-sosola-001.json": _temp44,
  "product-caviar.json": _temp45,
  "product-gum.json": _temp46,
  "product-lightningspray.json": _temp47,
  "product-playingcard.json": _temp48,
  "product-popcorn.json": _temp49,
  "product-squigglys.json": _temp50,
  "weight-arabic-ain-004.json": _temp51,
  "weight-cyr-backwardsye-001.json": _temp52,
  "weight-devanagari-dddha-002.json": _temp53,
  "weight-greek-sigma-001.json": _temp54,
  "weight-latin-e-002.json": _temp55,
  "weight-latin-s-006.json": _temp56,
  "weight-latin-uppercase-s-001.json": _temp57,
  "weight-latin-uppercase-s-nohandles-001.json": _temp58,
  "weight-thai-thothong-005.json": _temp59
}