const StickySubnav = (el) => {
  const onResize = () => {
    const top = (window.innerHeight - el.getBoundingClientRect().height) / 2;
    el.style.setProperty("--top", `${top}px`);
  };

  const onInViewChange = (entries) => {
    if (entries[0].isIntersecting) {
      onResize();
    }
  };

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });
  inViewObserver.observe(el);

  onResize();
  window.addEventListener("resize", onResize);
};
export default StickySubnav;
