import {
  AnimationMixer,
  Camera,
  DoubleSide,
  LinearMipMapLinearFilter,
  LinearMipMapNearestFilter,
  LoopOnce,
  LoopRepeat,
  Mesh,
  MeshBasicMaterial,
  NearestFilter,
  NearestMipMapLinearFilter,
  NearestMipmapNearestFilter,
  OrthographicCamera,
  PerspectiveCamera,
  Scene,
  Spherical,
  Texture,
  WebGLRenderer,
} from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import { convertToRange, getScrollTop } from "../util/lib";
import { clamp } from "lodash";
import { lerp } from "three/src/math/MathUtils";

const SectionScene = (el: HTMLElement, gltfLoader: GLTFLoader) => {
  const debugEl = el.querySelector("span") as HTMLSpanElement;
  const scene = new Scene();
  const src =
    window.innerWidth / window.innerHeight > 0.75
      ? el.dataset.src
      : el.dataset.srcMobile;

  // scene.overrideMaterial = new MeshBasicMaterial({
  //   wireframe: true,
  //   color: 0xff0000,
  // });
  const autoplayUntil = parseFloat(el.dataset.autoplayUntil || "0");
  const startScrollOffset = parseFloat(el.dataset.startScrollOffset || "0");
  const endScrollOffset = parseFloat(el.dataset.endScrollOffset || "0");
  let shouldAutoplay = autoplayUntil > 0;
  const cameraPositionSpherical = new Spherical(15, 0, 0);
  // scene.add(new AxesHelper(10));
  let camera: Camera = new PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );
  (camera as PerspectiveCamera).position.setFromSpherical(
    cameraPositionSpherical
  );
  let mixer: AnimationMixer;
  let maxAnimationDuration = 0;
  // const controls = new OrbitControls(
  //   camera,
  //   document.querySelector("canvas") as HTMLElement
  // );
  // controls.enableZoom = false;

  let raf;
  let min, max, scrollMin, scrollMax;
  let minScrollPc = 0; // clamp the lowest percent (for use when autoplay until)
  let latestRect;
  let isInView = false;
  let clipActions;

  const ready = new Promise(async (resolve) => {
    const gltf = await gltfLoader.loadAsync(src as string);
    gltf.scene.traverse((child) => {
      // We don't need to use PBR for these graphics, so switch to use basic materials for performance
      if (
        child instanceof Mesh &&
        !(child.material instanceof MeshBasicMaterial)
      ) {
        if (child.material.map) {
          (child.material.map as Texture).minFilter = NearestFilter;
          (child.material.map as Texture).magFilter = NearestFilter;
        }
        const newMaterial = new MeshBasicMaterial({
          map: child.material.map,
          side: DoubleSide,
        });
        child.material.dispose();
        child.material = newMaterial;
      }
    });
    scene.add(gltf.scene);
    mixer = new AnimationMixer(gltf.scene);
    clipActions = gltf.animations.map((animationClip) => {
      if (animationClip.duration > maxAnimationDuration) {
        maxAnimationDuration = animationClip.duration;
      }
      const clipAction = mixer.clipAction(animationClip);
      if (shouldAutoplay) {
        clipAction.loop = LoopOnce;
        clipAction.clampWhenFinished = true;
      }
      clipAction.play();
      return clipAction;
    });

    if (gltf.cameras.length > 0) {
      camera = gltf.cameras[0];
      onResize();
    }
    resolve(gltf);
  });
  const pollScroll = (loop = true) => {
    const st = getScrollTop();

    let scrollPc = clamp((st - scrollMin) / (scrollMax - scrollMin), 0, 1);
    const animPc = convertToRange(
      scrollPc,
      [minScrollPc, 1 + minScrollPc],
      [autoplayUntil, 1]
    );

    mixer?.setTime(animPc * maxAnimationDuration);
    // console.log(
    //   `Total progress: ${animPc.toFixed(2)}, ${scrollPc * maxAnimationDuration}`
    // );

    if (loop) {
      raf = requestAnimationFrame(() => pollScroll());
    }
  };

  const setMinMax = (rect) => {
    latestRect = rect;
    const st = getScrollTop();
    min = rect.top + st - window.innerHeight;
    max = rect.bottom + st;

    const totalScrollDistance = max - min;
    scrollMin = min + startScrollOffset * totalScrollDistance;
    scrollMax = max - endScrollOffset * totalScrollDistance;
  };

  const onInViewChange = (entries) => {
    if (entries[0].isIntersecting) {
      isInView = true;
      setMinMax(entries[0].boundingClientRect);
      resizeObserver.observe(document.body);
      if (!shouldAutoplay) {
        raf = requestAnimationFrame(() => pollScroll());
      }
    } else {
      isInView = false;
      minScrollPc = 0;
      resizeObserver.unobserve(document.body);
      cancelAnimationFrame(raf);
    }
  };

  const resizeObserver = new ResizeObserver(() => {
    setMinMax(el.getBoundingClientRect());
  });

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });

  inViewObserver.observe(el);

  const update = (delta) => {
    if (!!mixer && shouldAutoplay) {
      if (
        mixer.time > maxAnimationDuration * autoplayUntil &&
        autoplayUntil < 1
      ) {
        shouldAutoplay = false;
        const st = getScrollTop();
        minScrollPc = clamp((st - scrollMin) / (scrollMax - scrollMin), 0, 1);
        clipActions.forEach((action) => {
          action.loop = LoopRepeat;
          action.clampWhenFinished = false;
        });
        raf = requestAnimationFrame(() => pollScroll());
      }
      mixer?.update(delta);
    }
  };

  const setRendererToViewport = (renderer: WebGLRenderer) => {
    if (!latestRect || !isInView) return false;
    if (camera instanceof OrthographicCamera) {
      camera.left = renderer.domElement.width / -2;
      camera.right = renderer.domElement.width / 2;
      camera.top = renderer.domElement.height / 2;
      camera.bottom = renderer.domElement.height / -2;
      camera.updateProjectionMatrix();
    }

    const st = getScrollTop();

    const bottom = max - st;
    const positiveYUpBottom = renderer.domElement.clientHeight - bottom;
    renderer.setScissor(
      latestRect.left,
      positiveYUpBottom,
      latestRect.width,
      latestRect.height
    );
    renderer.setViewport(
      latestRect.left,
      positiveYUpBottom,
      latestRect.width,
      latestRect.height
    );
  };

  const onResize = () => {
    latestRect = el.getBoundingClientRect();
    if (camera instanceof PerspectiveCamera) {
      camera.aspect = latestRect.width / latestRect.height;
      camera.updateProjectionMatrix();
    }
  };

  return {
    ready,
    update,
    setRendererToViewport,
    onResize,
    getScene: () => scene,
    getCamera: () => camera,
    getIsInView: () => isInView,
  };
};

export default SectionScene;
