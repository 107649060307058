import { getScrollTop } from "../util/lib";

const SubnavJumpTo = (el) => {
  const sectionEl = document.querySelector(
    `[data-subnav-jump-to=${el.dataset.subnavJumpToTarget}]`
  );
  if (!sectionEl) return;
  let raf;
  let offset = 0;
  const minMax = { min: 0, max: 0 };

  const onClick = (e) => {
    sectionEl.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const pollScroll = (loop = true) => {
    const st = getScrollTop() + offset;

    if (st >= minMax.min && st < minMax.max) {
      el.classList.add("nav-section-indicator-active");
    } else {
      el.classList.remove("nav-section-indicator-active");
    }

    if (loop) {
      raf = requestAnimationFrame(() => pollScroll());
    }
  };

  const onResize = () => {
    offset = window.innerHeight * 0.33;
    const st = getScrollTop();
    const rect = sectionEl.getBoundingClientRect();
    // const offset = window.innerHeight * 0.33;
    minMax.min = rect.top + st;
    minMax.max = minMax.min + rect.height;
  };

  const onInViewChange = (entries) => {
    if (entries[0].isIntersecting) {
      raf = requestAnimationFrame(() => pollScroll());
      onResize();
    } else {
      cancelAnimationFrame(raf);
    }
  };

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });
  inViewObserver.observe(el);

  onResize();
  window.addEventListener("resize", onResize);
  el.addEventListener("click", onClick);
};

export default SubnavJumpTo;
