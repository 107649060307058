import { theme } from "../../../tailwind.config";
import { debounce, clamp } from "lodash";
import { getScrollTop, lerp } from "../util/lib";
import textFit from "textfit";
import useOrientationOrMousePosition from "../util/useOrientationOrMousePosition";

const TEXT_FIT_CONFIG = {
  alignVert: false, // if true, textFit will align vertically using css tables
  alignHoriz: false, // if true, textFit will set text-align: center
  multiLine: false, // if true, textFit will not set white-space: no-wrap
  detectMultiLine: false, // disable to turn off automatic multi-line sensing
  minFontSize: 6,
  maxFontSize: 900,
  reProcess: true, // if true, textFit will re-process already-fit nodes. Set to 'false' for better performance
  widthOnly: true, // if true, textFit will fit text to element width, regardless of text height
  alignVertWithFlexbox: false, // if true, textFit will use flexbox for vertical alignment
};

const AxesOverview = (el) => {
  let orientationStuff;
  let userInteractionListenerEl = el;

  // If we want to apply the mousemove listener to a parent element (ie. to create a group of axes overview which react together as a group), add data-component="axes-overview-group" to the common ancestor
  let ancestor = el.parentElement;
  while (ancestor) {
    if (ancestor.dataset.component === "axes-overview-group") {
      userInteractionListenerEl = ancestor;
      break;
    }
    ancestor = ancestor.parentElement;
  }

  const sampleEl = el.querySelector("[data-component='axes-overview__sample']");
  const setFontSize = el.dataset.setSize !== "false";
  const widthOnly = el.dataset.fitHeight !== "true";

  const onResize = debounce(() => {
    if (setFontSize) {
      textFit(sampleEl, { ...TEXT_FIT_CONFIG, widthOnly });
    }

    const computedStyle = window.getComputedStyle(
      sampleEl.querySelector("span") || sampleEl
    );

    el.style.setProperty(
      `--font-size-text`,
      `"${parseInt(computedStyle.getPropertyValue("font-size"))}"`
    );
  }, 111);

  onResize();

  const setTypeVariationSettings = (pcX, pcY) => {
    if (el.dataset.xAxes) {
      const xAxesVal = lerp(
        parseFloat(el.dataset.xAxesMin),
        parseFloat(el.dataset.xAxesMax),
        pcX
      );
      el.style.setProperty(`--${el.dataset.xAxes}`, `${xAxesVal}`);
      el.style.setProperty(
        `--${el.dataset.xAxes}-text`,
        `"${Math.round(xAxesVal)}"`
      );
    }

    if (el.dataset.yAxes) {
      const yAxesVal = lerp(
        parseFloat(el.dataset.yAxesMin),
        parseFloat(el.dataset.yAxesMax),
        pcY
      );
      el.style.setProperty(`--${el.dataset.yAxes}`, `${yAxesVal}`);
      el.style.setProperty(
        `--${el.dataset.yAxes}-text`,
        `"${Math.round(yAxesVal)}"`
      );
    }
  };

  const onMouseMove = ([clientX, clientY], [pcX, pcY]) => {
    setTypeVariationSettings(pcX, pcY);
  };

  const onFirstDeviceMotion = () => {};

  const onDeviceOrientation = ([gamma, beta], [pcX, pcY]) => {
    setTypeVariationSettings(pcX, pcY);
  };

  const onInViewChange = (entries) => {
    if (entries[0].isIntersecting) {
      onResize();
      onResize.flush();
      orientationStuff.enable();
      window.addEventListener("resize", onResize);
    } else {
      orientationStuff.disable();
      window.removeEventListener("resize", onResize);
    }
  };

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });

  inViewObserver.observe(userInteractionListenerEl);

  orientationStuff = useOrientationOrMousePosition(
    onMouseMove,
    onDeviceOrientation,
    onFirstDeviceMotion,
    userInteractionListenerEl
  );
  el.addEventListener("click", orientationStuff.requestOrientationPermission);

  setTypeVariationSettings(0.5, 0.5);
};

export default AxesOverview;
