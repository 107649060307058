import { getScrollTop } from "../util/lib";

const CharSet = (el) => {
  const loupeEl = el.querySelector("[data-component='char-set__loupe']");
  const subsetEls = el.querySelectorAll(
    '[data-component*="char-set__set-container"]'
  );
  let subsetMinScroll = [];
  let raf;
  let min = 0;

  const setSubsetScrollPositions = () => {
    const st = getScrollTop();
    subsetMinScroll = [...subsetEls].map((subsetEl) => {
      const rect = subsetEl.getBoundingClientRect();
      min = rect.top + st - 50;
      const firstCharEl = subsetEl.querySelector(
        "[data-component='char-set__char']"
      );
      return {
        min,
        isVisible: rect.height > 0,
        char: firstCharEl.innerHTML.replace(/\s+/g, ""),
        lang: firstCharEl.getAttribute("lang"),
        script: subsetEl.dataset.charSetScript,
        subset: subsetEl.dataset.charSetSubset,
      };
    });
  };

  const activateChar = (
    char,
    script,
    subset,
    fontFeatureSettings = "",
    lang = "en"
  ) => {
    loupeEl.setAttribute("lang", lang);
    // No idea why the character innerHTML for the ampersand element is returning &amp; rather than the character
    if (char === "&amp;") {
      char = "&";
    }
    console.log(char);
    el.style.setProperty(`--char`, `"${char}"`);
    el.style.setProperty(`--unicode`, `"U+${char.codePointAt(0)}"`);
    el.style.setProperty(`--script`, `"${script}"`);
    el.style.setProperty(`--subset`, `"${subset}"`);
    el.style.setProperty(`--font-feature-settings`, fontFeatureSettings);
  };

  [...subsetEls].forEach((subsetEl) => {
    const characterEls = subsetEl.querySelectorAll(
      "[data-component='char-set__char']"
    );
    subsetEl.style.setProperty(`--subset-size`, `"${characterEls.length}"`);
    [...characterEls].forEach((charEl) => {
      charEl.addEventListener("pointerenter", () => {
        activateChar(
          charEl.innerHTML.replace(/\s+/g, ""),
          subsetEl.dataset.charSetScript,
          subsetEl.dataset.charSetSubset,
          window
            .getComputedStyle(charEl)
            .getPropertyValue("font-feature-settings"),
          charEl.getAttribute("lang")
        );
      });
    });
  });

  const pollScroll = (loop = true) => {
    const st = getScrollTop();

    for (let i = subsetMinScroll.length - 1; i >= 0; i--) {
      const { min, isVisible, char, lang, script, subset } = subsetMinScroll[i];
      if (!isVisible) continue;
      if (st >= min) {
        activateChar(char, script, subset);
        break;
      }
    }

    if (loop) {
      raf = requestAnimationFrame(() => pollScroll());
    }
  };

  const onScroll = () => {
    pollScroll(false);
  };

  const onInViewChange = (entries) => {
    if (entries[0].isIntersecting) {
      // setSubsetScrollPositions();
      resizeObserver.observe(document.body);
      document.body.addEventListener("scroll", onScroll, { passive: true });
    } else {
      resizeObserver.unobserve(document.body);
      document.body.removeEventListener("scroll", onScroll);
      cancelAnimationFrame(raf);
    }
  };

  const resizeObserver = new ResizeObserver(() => {
    setSubsetScrollPositions();
  });

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });
  inViewObserver.observe(el);

  // watch for in view change on the individual sets so taht it triggers when they show / hide
  const charSetInViewObserver = new IntersectionObserver(
    (entries) => {
      if (entries[0].isIntersecting) {
        setSubsetScrollPositions();
      }
    },
    {
      root: null,
    }
  );
  subsetEls.forEach((el) => charSetInViewObserver.observe(el));
  inViewObserver.observe(el);

  setSubsetScrollPositions();
  activateChar("A", "Latin", "Basic Alphabet", "en");
};
export default CharSet;
