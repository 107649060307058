import { clamp, debounce } from "lodash";
import { getScrollTop } from "../util/lib";
import tailwindConfig from "../../../tailwind.config";

const MobileScrollyHeader = (el) => {
  let currentPosition = 0;
  let st = getScrollTop();
  let previousSt = getScrollTop();
  let height = el.clientHeight;
  let raf;

  const pollScroll = () => {
    previousSt = st;
    st = getScrollTop();
    const delta = previousSt - st;
    currentPosition += delta;
    currentPosition = clamp(currentPosition, -height, 0);

    el.style.setProperty(`--top`, `${currentPosition}px`);

    raf = requestAnimationFrame(pollScroll);
  };

  const onResize = debounce(() => {
    height = el.clientHeight;
    currentPosition = 0;
    el.style.setProperty(`--top`, `${currentPosition}px`);

    if (window.innerWidth <= parseInt(tailwindConfig.theme.screens.md)) {
      raf = requestAnimationFrame(pollScroll);
    } else {
      cancelAnimationFrame(raf);
    }
  }, 99);

  window.addEventListener("resize", onResize);
  onResize();
};

export default MobileScrollyHeader;
