import { debounce, clamp, reject, throttle } from "lodash";

const useOrientationOrMousePosition = (
  mouseMoveCallback,
  deviceMotionCallback,
  firstDeviceMotionCallback = null,
  containerElement = null
) => {
  let width = window.innerWidth;
  let height = window.innerHeight;
  let hasDeviceOrientation = false;
  let containerRect = containerElement?.getBoundingClientRect();

  const onResize = debounce(() => {
    containerRect = containerElement?.getBoundingClientRect();
    width = window.innerWidth;
    height = window.innerHeight;
  }, 111);

  const onMouseMove = throttle((e) => {
    if (hasDeviceOrientation) return;

    const event = e.touches ? e.touches[0] : e;
    const { clientX, clientY } = event;

    let pcX, pcY;
    if (containerRect) {
      pcX = clamp((clientX - containerRect.left) / containerRect.width, 0, 1);
      pcY = clamp((clientY - containerRect.top) / containerRect.height, 0, 1);
    } else {
      pcX = clamp(clientX / width, 0, 1);
      pcY = clamp(clientY / height, 0, 1);
    }

    if (mouseMoveCallback) {
      requestAnimationFrame(() => {
        mouseMoveCallback([clientX, clientY], [pcX, pcY]);
      });
    }
  }, 16.66);

  const onFirstDeviceMotion = () => {
    window.hasDeviceOrientation = true;
    hasDeviceOrientation = true;
    document.body.classList.add("js-tilt-mode-active");
    window.removeEventListener("touchmove", onMouseMove);
    window.removeEventListener("mousemove", onMouseMove);

    if (firstDeviceMotionCallback) {
      firstDeviceMotionCallback();
    }
  };

  const onDeviceOrientation = throttle((e) => {
    if (!e.gamma || !e.beta) return; // New version of Chrome is triggering a random devicemotion event (in which all the axes are null...?!)
    if (!hasDeviceOrientation) {
      onFirstDeviceMotion();
    }

    const pcX = clamp((e.gamma + 45) / 90, 0, 1);
    const pcY = clamp((e.beta + 10) / 90, 0, 1);

    if (deviceMotionCallback) {
      requestAnimationFrame(() => {
        deviceMotionCallback([e.gamma, e.beta], [pcX, pcY]);
      });
    }
  }, 16.66);

  const onScroll = throttle(() => {
    if (!containerElement) return;

    requestAnimationFrame(() => {
      containerRect = containerElement?.getBoundingClientRect();
    });
  }, 66);

  const enable = () => {
    onScroll();
    onScroll.flush();
    containerRect = containerElement?.getBoundingClientRect();
    document.body.addEventListener("scroll", onScroll, { passive: true });
    window.addEventListener("deviceorientation", onDeviceOrientation);
    if (!hasDeviceOrientation) {
      window.addEventListener("touchmove", onMouseMove);
      window.addEventListener("mousemove", onMouseMove);
    }
    window.addEventListener("resize", onResize);
  };

  const disable = () => {
    document.body.removeEventListener("scroll", onScroll);
    window.removeEventListener("deviceorientation", onDeviceOrientation);
    window.removeEventListener("touchmove", onMouseMove);
    window.removeEventListener("mousemove", onMouseMove);
    window.removeEventListener("resize", onResize);
  };

  const requestOrientationPermission = async () => {
    if (
      typeof DeviceMotionEvent !== "undefined" &&
      typeof DeviceMotionEvent.requestPermission === "function"
    ) {
      // iOS 13+
      try {
        await DeviceOrientationEvent.requestPermission();
        window.removeEventListener("deviceorientation", onDeviceOrientation);
        window.addEventListener("deviceorientation", onDeviceOrientation);
        return true;
      } catch (error) {
        console.warn(error);
        return false;
      }
    } else {
      console.warn("Orientation not supported");
      return false;
    }
  };

  return { requestOrientationPermission, enable, disable };
};

export default useOrientationOrMousePosition;
