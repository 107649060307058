import { theme } from "../../../tailwind.config";
import { parseFontVariationSettings } from "../util/lib";

const FamilyOverviewTable = (el) => {
  const isTouch =
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;
  const hasRowHeader = el.dataset.hasRowHeader !== "false";

  const cells = [...el.querySelectorAll("td")];
  const columnHeaders = [...el.querySelector("tr").querySelectorAll("th")];
  if (hasRowHeader) {
    // remove frist as it's empty;
    columnHeaders.shift();
  }
  const rowHeaders = hasRowHeader
    ? [...el.querySelectorAll("tr")].map((row) => row.querySelector("th"))
    : null;
  if (rowHeaders) {
    rowHeaders.shift();
  }
  const columnCount = columnHeaders.length; // -1 to account for header

  const setDataAttributes = () => {
    cells.forEach((cell, i) => {
      const computedStyle = window.getComputedStyle(cell);
      const fontVariationSettings = computedStyle.getPropertyValue(
        "font-variation-settings"
      );
      const fontFamily = computedStyle.getPropertyValue("font-family");
      const { wght, ital, wdth } = parseFontVariationSettings(
        fontVariationSettings
      );
      cell.dataset.wght = wght;
      cell.dataset.wdth = wdth === undefined ? 100 : wdth;
      cell.dataset.ital = ital;
      cell.dataset.fontFamily = fontFamily;
      cell.dataset.columnIndex = i % columnCount;
      cell.dataset.rowIndex = Math.floor(i / columnCount);
    });
  };

  const onMouseEnterCell = (_e) => {
    const e = _e.touches ? _e.touches[0] : _e;
    el.classList.add("active");
    const { wght, wdth, ital, fontFamily, columnIndex, rowIndex } =
      e.target.dataset;
    columnHeaders[parseInt(columnIndex)].classList.add("active");
    if (rowHeaders) {
      rowHeaders[parseInt(rowIndex)].classList.add("active");
    }

    cells.forEach((cell) => {
      cell.style.setProperty("--font-variation-wght", wght);
      cell.style.setProperty("--font-variation-wdth", wdth);
      cell.style.setProperty("--font-variation-ital", ital);
      cell.style.setProperty("--font-family", fontFamily);
    });
  };

  const onMouseLeaveCell = (e) => {
    el.classList.remove("active");
    if (e.target) {
      e.target.classList.remove("js-family-overview-cell-hover");
    }

    const { columnIndex, rowIndex } = e.target.dataset;
    columnHeaders[parseInt(columnIndex)].classList.remove("active");
    if (rowHeaders) {
      rowHeaders[parseInt(rowIndex)].classList.remove("active");
    }

    cells.forEach((cell) => {
      cell.style.setProperty("--font-variation-wght", "");
      cell.style.setProperty("--font-variation-wdth", "");
      cell.style.setProperty("--font-variation-ital", "");
      cell.style.setProperty("--font-family", "");
    });
  };

  cells.forEach((cell) => {
    if (isTouch) {
      cell.addEventListener("touchstart", onMouseEnterCell);
      cell.addEventListener("touchend", onMouseLeaveCell);
    } else {
      cell.addEventListener("mouseenter", onMouseEnterCell);
      cell.addEventListener("mouseleave", onMouseLeaveCell);
    }
  });
  window.addEventListener("touchend", () => setTimeout(onMouseLeaveCell, 0));

  setDataAttributes();
};

export default FamilyOverviewTable;
