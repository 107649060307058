const Loader = async () => {
  if (document.fonts) {
    await document.fonts.ready;
    console.log("loaded fonts", document.fonts);
    await document.fonts.load('16px "GT America Intl Latin Subset"');
  }
  document.body.classList.add("fonts-loaded");
  document.body.classList.add("is-init");

  console.log("Fonts loaded");
};

export default Loader;
