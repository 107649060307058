import { Clock, LoadingManager, WebGLRenderer } from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import LottiePlayer from "./components/LottiePlayer";
import { debounce } from "lodash";
import SectionScene from "./components/SectionScene";
import StickyNavItem from "./components/StickyNavItem";
import NavColourSwitcher from "./components/NavColourSwitcher";
import StickySubnav from "./components/StickySubnav";
import AxesOverview from "./components/AxesOverview";
import SubnavJumpTo from "./components/SubnavJumpTo";
import CharSet from "./components/CharSet";
import MobileScrollyHeader from "./components/MobileScrollyHeader";
import FamilyOverviewTable from "./components/FamilyOverviewTable";
import AvailScriptsAnimationGroup from "./components/AvailScriptsAnimationGroup";
import TypetesterSample from "./components/TypetesterSample";
import ShowHideSection from "./components/ShowHideSection";
import Loader from "./components/Loader";

const LOAD_PNG_AS_KTX2 = false;

const clock = new Clock();

const renderer = new WebGLRenderer({ alpha: true });
document.body.appendChild(renderer.domElement);
renderer.domElement.style.position = "fixed";
renderer.domElement.style.top = "0px";
renderer.domElement.style.left = "0px";
renderer.domElement.style.width = "100%";
renderer.domElement.style.height = "100%";
renderer.domElement.style.pointerEvents = "none";
renderer.setScissorTest(true);
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setPixelRatio(Math.max(window.devicePixelRatio, 2));

const loadingManager = new LoadingManager();
if (LOAD_PNG_AS_KTX2) {
  loadingManager.setURLModifier((url) => {
    return url.replace(".png", ".ktx2");
  });
}

const gltfLoader = new GLTFLoader(loadingManager);
const dracoLoader = new DRACOLoader(loadingManager);
dracoLoader.setDecoderPath("libs/draco/");
gltfLoader.setDRACOLoader(dracoLoader);
const ktx2Loader = new KTX2Loader(loadingManager);
ktx2Loader.setTranscoderPath("libs/basis/");
ktx2Loader.detectSupport(renderer);

if (LOAD_PNG_AS_KTX2) {
  // not sure why .setKTX2Loader alone isn't working for this
  loadingManager.addHandler(/\.png$/i, ktx2Loader);
  gltfLoader.setKTX2Loader(ktx2Loader);
}

// loadingManager.onStart = function (url, itemsLoaded, itemsTotal) {
//   console.log(
//     "Started loading file: " +
//       url +
//       ".\nLoaded " +
//       itemsLoaded +
//       " of " +
//       itemsTotal +
//       " files."
//   );
// };

loadingManager.onLoad = function () {
  console.log("Loading complete!");
};

// loadingManager.onProgress = function (url, itemsLoaded, itemsTotal) {
//   console.log(
//     "Loading file: " +
//       url +
//       ".\nLoaded " +
//       itemsLoaded +
//       " of " +
//       itemsTotal +
//       " files."
//   );
// };

const render = () => {
  sectionScenes.forEach((section) => {
    const { getScene, getCamera, getIsInView, setRendererToViewport } = section;
    if (!getIsInView()) return;
    setRendererToViewport(renderer);
    renderer.render(getScene(), getCamera());
    // const { memory, render } = renderer.info;
    // console.log(
    //   `Geometries: ${memory.geometries}, Textures: ${memory.textures}`
    // );
    // console.log(`Calls: ${render.calls}, Triangles: ${render.triangles}`);
  });
};

const update = (delta) => {
  sectionScenes.forEach((section) => {
    const { getIsInView } = section;
    if (!getIsInView()) return;
    section.update(delta);
  });
};

const animate = () => {
  update(clock.getDelta());
  render();
  requestAnimationFrame(animate);
};

const onResize = debounce(() => {
  requestAnimationFrame(() => {
    document.documentElement.style.setProperty(
      "--visible-viewport-height",
      `${window.innerHeight}px`
    );
  });

  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setPixelRatio(Math.max(window.devicePixelRatio, 2));
  sectionScenes.forEach(({ onResize: _onResize }) => {
    _onResize();
  });
}, 111);

const loader = Loader();

const sectionScenes = [
  ...document.querySelectorAll("[data-component='section-scene']"),
].map((el, i) => {
  return SectionScene(el, gltfLoader);
});

const lottieEls = [
  ...document.querySelectorAll("[data-component*='lottie-player']"),
];
lottieEls.forEach((el) => LottiePlayer(el));

const mobileScrollyHeaderEls = [
  ...document.querySelectorAll("[data-component*='mobile-scrolly-header']"),
];
mobileScrollyHeaderEls.forEach((el) => MobileScrollyHeader(el));

const stickyNavEls = [
  ...document.querySelectorAll("[data-component*='sticky-nav-item']"),
];
stickyNavEls.forEach((el) => StickyNavItem(el));

const stickySubnavEls = [
  ...document.querySelectorAll("[data-component*='sticky-subnav']"),
];
stickySubnavEls.forEach((el) => StickySubnav(el));

const subnavJumpToEls = [
  ...document.querySelectorAll("[data-component*='subnav-jump-to']"),
];
subnavJumpToEls.forEach((el) => SubnavJumpTo(el));

const navColourSwitcherEls = [
  ...document.querySelectorAll("[data-component*='nav-colour-switcher']"),
];
navColourSwitcherEls.forEach((el) => NavColourSwitcher(el));

const axesOverview = [
  ...document.querySelectorAll("[data-component='axes-overview']"),
];
axesOverview.forEach((el) => AxesOverview(el));

const charSet = [...document.querySelectorAll("[data-component='char-set']")];
charSet.forEach((el) => CharSet(el));

const familyOverviewTableEls = [
  ...document.querySelectorAll("[data-component='family-overview-table']"),
];
familyOverviewTableEls.forEach((el) => FamilyOverviewTable(el));

const availScriptsAnimationGroupEls = [
  ...document.querySelectorAll(
    "[data-component='avail-scripts-animation-group']"
  ),
];
availScriptsAnimationGroupEls.forEach((el) => AvailScriptsAnimationGroup(el));

const typetesterSampleEls = [
  ...document.querySelectorAll("[data-component='tt__sample']"),
];
typetesterSampleEls.forEach((el) => TypetesterSample(el));

const showHideSectionEls = [
  ...document.querySelectorAll("[data-component='show-hide-section']"),
];
showHideSectionEls.forEach((el) => ShowHideSection(el));

const ttToggles = [
  ...document.querySelectorAll("[data-component='tt-toggle']"),
];
ttToggles.forEach((el) => {
  el.addEventListener("pointerenter", () => {
    document.body.classList.add("tt-teaser");
  });
  el.addEventListener("pointerleave", () => {
    document.body.classList.remove("tt-teaser");
  });
  el.addEventListener("click", () => {
    document.body.classList.remove("tt-teaser");
    document.body.classList.toggle("tt-active");
  });
});

const ttClose = [...document.querySelectorAll("[data-component='tt-close']")];
ttClose.forEach((el) => {
  el.addEventListener("click", () => {
    document.body.classList.remove("tt-active");
  });
});

onResize();
onResize.flush();
window.addEventListener("resize", onResize);
requestAnimationFrame(animate);
