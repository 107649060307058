// This file controls how Tailwind processes your CSS. For details, see
// https://tailwindcss.com/docs/configuration
const safelist = ["red", "blue", "white"].reduce((acc, value) => {
  return [
    ...acc,
    `border-${value}`,
    `background-${value}`,
    `hover:border-${value}`,
    `hover:bg-${value}`,
    `checked:border-${value}`,
    `checked:bg-${value}`,
  ];
}, []);

module.exports = {
  content: ["./source/**/*.{html,js}"],
  safelist,

  //
  // All other TailwindCSS options are 100% under your control. Edit this config file as shown in the Tailwind Docs
  // to enable the settings or customizations you need.
  //

  theme: {
    // GT America Intl Break Points
    screens: {
      sm: "830px",
      md: "1180px",
      lg: "1440px",
      xl: "1720px",
      xxl: "2000px",
    },

    // GT America Intl Color Scheme
    colors: {
      transparent: "transparent",
      current: "currentColor",
      white: "#ffffff",
      black: "#000000",
      red: "#FF3500",
      blue: "#0000FF",
      // gray: {
      //   DEFAULT: "#B5BDBF",
      //   dk: "#606060",
      // },
      gray: "#D2D2D2", // Remove once removed from codebase
      beige: "#D2D2D2", // Remove once removed from codebase
    },

    fontFamily: {
      sans: [
        "GT America Intl",
        "GT America Intl Mono",
        "GT America Korean",
        "Comic Sans MS",
        "cursive",
      ],
      korean: ["GT America Korean"],
      arabic: ["GT America Arabic"],
    },

    fontSize: {
      // Add letter-spacing where necessary like this
      // '2xl': ['24px', {
      //   letterSpacing: '-0.01em',
      // }],
      xxs: ["9px", "10px"],
      xs: ["12px", "14px"],
      sm: ["50px", "24px"], // Remove once removed from codebase
      base: ["12px", { letterSpacing: "0.03em", lineHeight: "14px" }],
      rg: ["14px", { letterSpacing: "0.005em", lineHeight: "16px" }],
      md: ["18px", { letterSpacing: "0em", lineHeight: "22px" }],
      lg: ["21px", "24px"],
      xl: ["36px", "40px"],
      "2xl": ["60px", "67px"], // Move to vw if necessary
      "3xl": ["108px", "121px"], // Move to vw if necessary
      "4xl": ["11vw", "90%"], // Adjust a little later
      "5xl": ["24vw", "80%"], // Adjust a little later
      "6xl": ["40vw", "80%"], // Adjust a little later
      "7xl": ["60vw", "80%"], // Adjust a little later
      "8xl": ["80vw", "80%"], // Adjust a little later
    },

    spacing: {
      px: "1px",
      0: "0",
      1: "1px",
      2: "3px",
      3: "6px",
      4: "12px",
      5: "18px",
      6: "24px",
      7: "36px",
      8: "48px",
      9: "60px",
      10: "96px",
      11: "144px",
      12: "192px",
    },

    extend: {
      // For extending any existing themes, i.e. a larger breakpoint
    },
  },

  variants: {},

  //
  // If you want to run any Tailwind plugins (such as 'tailwindcss-typography'), simply install those into the Project via the
  // Packages area in CodeKit, then pass their names (and, optionally, any configuration values) here.
  // Full file paths are not necessary; CodeKit will find them.
  //
  plugins: [require("tailwindcss-crossbrowser-touch")()],
};
