import { getScrollTop } from "../util/lib";

const LINE_HEIGHT = 16;
const OFFSET_TOP = 80;
const OFFSET_BOTTOM = 45;

const StickyNavItem = (el) => {
  let raf;
  const innerEl = el.querySelector("button");
  const sectionEl = document.querySelector(
    `[data-nav-section=${el.dataset.navSectionTarget}]`
  );
  const anchorEl = sectionEl.querySelector("h2");
  const itemIndex = parseInt(el.dataset.index);
  const positionTop = itemIndex * LINE_HEIGHT + OFFSET_TOP;
  let positionBottom = window.innerHeight - (4 - itemIndex) * LINE_HEIGHT;
  let height = 2000;

  const thisSiblingIndex = [...el.parentElement.children].indexOf(el);
  const nextSiblings = [...el.parentElement.children].filter(
    (_el, index) => index > thisSiblingIndex
  );

  const pollScroll = (loop = true) => {
    const st = getScrollTop();

    if (height - st < positionBottom + LINE_HEIGHT) {
      el.classList.add("sticky-nav-item--passed");

      if (
        nextSiblings.every(
          (sibling) => !sibling.classList.contains("sticky-nav-item--passed")
        )
      ) {
        el.classList.add("sticky-nav-item--active");
      } else {
        el.classList.remove("sticky-nav-item--active");
      }
    } else {
      el.classList.remove("sticky-nav-item--passed");
      el.classList.remove("sticky-nav-item--active");
    }

    if (st > height - positionTop) {
      el.style.position = "fixed";
      el.style.top = `${-height + positionTop}px`;
      // Fix for Firefox
      innerEl.style.position = "absolute";
      innerEl.style.bottom = "0";
      innerEl.style.top = "unset";
    } else {
      el.style.position = "absolute";
      el.style.top = `0px`;
      innerEl.style.position = "";
      innerEl.style.bottom = "";
      innerEl.style.top = "";
    }

    if (loop) {
      raf = requestAnimationFrame(() => pollScroll());
    }
  };

  const onClick = () => {
    sectionEl.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  innerEl.addEventListener("click", onClick);

  const onInViewChange = (entries) => {
    if (entries[0].isIntersecting) {
      raf = requestAnimationFrame(() => pollScroll());
      onResize();
    } else {
      cancelAnimationFrame(raf);
    }
  };

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });
  inViewObserver.observe(el);

  const onResize = () => {
    const scrollTop = getScrollTop();
    height = anchorEl.getBoundingClientRect().top + scrollTop + LINE_HEIGHT * 3;
    positionBottom =
      window.innerHeight -
      LINE_HEIGHT -
      OFFSET_BOTTOM -
      (3 - itemIndex) * LINE_HEIGHT;
    el.style.setProperty("--height", `${height}px`);
    el.style.setProperty("--inner-top", `${positionBottom}px`);
  };
  onResize();

  window.addEventListener("resize", onResize);
};
export default StickyNavItem;
