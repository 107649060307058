import { shuffle } from "lodash";
import presets from "../../config/typetester-presets.json";

const TypetesterSample = (el) => {
  const sampleOptionsEl = el.querySelector(
    '[data-component="tt__sample-options"]'
  );
  const sampleTextEl = el.querySelector('[data-component="tt__sample-text"]');
  const scriptSelectEl = el.querySelector(
    "[data-component='tt__sample-options__script']"
  );
  const scriptSelectedOption = scriptSelectEl.querySelector("option[selected]");
  const styleSelectEls = [
    ...el.querySelectorAll("[data-component='tt__sample-options__styles']"),
  ];
  const disabledInMonoOptions = [
    ...el.querySelectorAll("[data-disabled-in-mono='true']"),
  ];
  const showInScriptEls = [
    ...sampleOptionsEl.querySelectorAll("[data-show-in-script]"),
  ];
  const propMonoRadio = [
    ...sampleOptionsEl.querySelectorAll("[name='prop-mono']"),
  ];
  const italRadio = [
    ...sampleOptionsEl.querySelectorAll("[name='upright-ital']"),
  ];
  const altsRadio = [
    ...sampleOptionsEl.querySelectorAll("[name='alternatives']"),
  ];
  const loopedRadio = [...sampleOptionsEl.querySelectorAll("[name='looped']")];
  const wdthSliderWrapper = sampleOptionsEl.querySelector(
    "[data-component='tt__wdth-slider-wrapper']"
  );
  const wdthSlider = sampleOptionsEl.querySelector(
    "[data-component='tt__wdth-slider']"
  );
  const wghtSlider = sampleOptionsEl.querySelector(
    "[data-component='tt__wght-slider']"
  );
  let script = scriptSelectedOption.dataset.script;
  let lang = scriptSelectedOption.dataset.lang;
  let direction = scriptSelectedOption.dataset.direction;

  let isItal = !!sampleOptionsEl.querySelector(
    "[name='upright-ital'][value='italic']:checked"
  );
  let isMono = !!sampleOptionsEl.querySelector(
    "[name='prop-mono'][value='mono']:checked"
  );
  let isLooped = !!sampleOptionsEl.querySelector(
    "[name='looped'][value='looped']:checked"
  );
  let isAlternatives = !!sampleOptionsEl.querySelector(
    "[name='looped'][value='alternatives']:checked"
  );
  // let style = "variable";
  let wght = wghtSlider.value;
  let wdth = wdthSlider.value;

  const allFontClasses = [
    "italic-fonts",
    "mono-fonts",
    "mono-italic-fonts",
    "thai-looped-fonts",
    "thai-loopless-fonts",
  ];

  const computeFontClass = () => {
    if (script === "thai" && isLooped) {
      return "thai-looped-fonts";
    }
    if (script === "thai" && !isLooped) {
      return "thai-loopless-fonts";
    }
    if (script === "latin" && !isMono && isItal) {
      return "italic-fonts";
    }
    if (script === "latin" && isMono && !isItal) {
      return "mono-fonts";
    }
    if (script === "latin" && isMono && isItal) {
      return "mono-italic-fonts";
    }
    if (script === "cyrillic" && !isMono && isItal) {
      return "italic-fonts";
    }
    if (script === "cyrillic" && isMono && !isItal) {
      return "mono-fonts";
    }
    if (script === "cyrillic" && isMono && isItal) {
      return "mono-italic-fonts";
    }
    if (script === "greek" && !isMono && isItal) {
      return "italic-fonts";
    }
    if (script === "greek" && isMono && !isItal) {
      return "mono-fonts";
    }
    if (script === "greek" && isMono && isItal) {
      return "mono-italic-fonts";
    }
    if (script === "vietnamese" && !isMono && isItal) {
      return "italic-fonts";
    }
    if (script === "vietnamese" && isMono && !isItal) {
      return "mono-fonts";
    }
    if (script === "vietnamese" && isMono && isItal) {
      return "mono-italic-fonts";
    }

    return null; // no class, falls back to default root typeface (upright proportional)
  };

  const updateSettings = () => {
    allFontClasses.forEach((className) => {
      sampleTextEl.classList.remove(className);
    });
    sampleTextEl.classList.add(computeFontClass());
    if (isAlternatives) {
      sampleTextEl.classList.add("ot-ssplusarrow");
    } else {
      sampleTextEl.classList.remove("ot-ssplusarrow");
    }

    el.style.setProperty("--wght", wght);
    el.style.setProperty("--wdth", wdth);
    el.style.setProperty("--wght-text", `"${wght}"`);
    el.style.setProperty("--wdth-text", `"${wdth}"`);
    wghtSlider.value = wght;
    wdthSlider.value = wdth;
    sampleTextEl.setAttribute("lang", lang);
    sampleTextEl.setAttribute("dir", direction);

    // Show / hide options based on font
    el.dataset.script = script;
    sampleOptionsEl.dataset.script = script;

    showInScriptEls.forEach((el) => el.setAttribute("aria-hidden", "true"));
    const visibleInScriptEls = [
      ...sampleOptionsEl.querySelectorAll(`[data-show-in-script*="${script}"]`),
    ];
    visibleInScriptEls.forEach((el) => el.removeAttribute("aria-hidden"));

    if (isMono) {
      disabledInMonoOptions.forEach((el) => (el.disabled = true));
      disabledInMonoOptions.forEach((el) => (el.style.display = "none"));
    } else {
      disabledInMonoOptions.forEach((el) => (el.disabled = false));
      disabledInMonoOptions.forEach((el) => (el.style.display = ""));
    }

    if (script === "latin" && isMono) {
      wdthSliderWrapper.setAttribute("hidden", "true");
      wdthSliderWrapper.setAttribute("aria-hidden", "true");
    } else {
      wdthSliderWrapper.removeAttribute("hidden");
      wdthSliderWrapper.removeAttribute("aria-hidden");
    }
  };

  const updatePresetText = () => {
    const possiblePresets = shuffle(
      presets.presets.filter(({ script: presetScript, size }) => {
        return presetScript === script && size === sampleTextEl.dataset.size;
      })
    );

    if (possiblePresets.length) {
      sampleTextEl.innerHTML = possiblePresets[0].text;
    }
  };

  styleSelectEls.forEach((el) => {
    el.addEventListener("change", (e) => {
      const option = e.target.options[e.target.selectedIndex];
      if (!!option.dataset.wght) {
        wght = parseInt(option.dataset.wght);
      }
      if (!!option.dataset.wdth) {
        wdth = parseInt(option.dataset.wdth);
      }
      updateSettings();
    });
  });

  propMonoRadio.forEach((el) => {
    el.addEventListener("change", (e) => {
      isMono = e.target.value === "mono";
      updateSettings();
    });
  });

  italRadio.forEach((el) => {
    el.addEventListener("change", (e) => {
      isItal = e.target.value === "italic";
      updateSettings();
    });
  });

  loopedRadio.forEach((el) => {
    el.addEventListener("change", (e) => {
      isLooped = e.target.value === "looped";
      updateSettings();
    });
  });

  altsRadio.forEach((el) => {
    el.addEventListener("change", (e) => {
      isAlternatives = e.target.value === "alternatives";
      updateSettings();
    });
  });

  scriptSelectEl.addEventListener("change", (e) => {
    const option = e.target.options[e.target.selectedIndex];
    script = option.dataset.script;
    lang = option.dataset.lang;
    direction = option.dataset.direction;
    updatePresetText();
    updateSettings();
  });

  wdthSlider.addEventListener("input", (e) => {
    styleSelectEls.forEach((el) => {
      el.value = "variable";
    });
    wdth = e.target.value;
    updateSettings();
  });

  wghtSlider.addEventListener("input", (e) => {
    styleSelectEls.forEach((el) => {
      el.value = "variable";
    });
    wght = e.target.value;
    updateSettings();
  });

  updatePresetText();
  updateSettings();
};

export default TypetesterSample;
