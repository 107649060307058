const ShowHideSection = (el) => {
  const sectionEls = [
    ...el.querySelectorAll("[data-component*='show-hide-section__section']"),
  ];
  const radioEls = [
    ...el.querySelectorAll(
      "[data-component='show-hide-section__radios'] input"
    ),
  ];
  let currentSection = el.querySelector(
    "[data-component='show-hide-section__radios'] input[checked]"
  ).value;

  const showSection = () => {
    sectionEls.forEach((sectionEl) => {
      if (sectionEl.dataset.showHideSectionName === currentSection) {
        sectionEl.removeAttribute("aria-hidden");
        sectionEl.removeAttribute("hidden");
      } else {
        sectionEl.setAttribute("aria-hidden", "true");
        sectionEl.setAttribute("hidden", "true");
      }
    });
  };

  radioEls.forEach((radioEl) => {
    radioEl.addEventListener("change", (e) => {
      currentSection = e.target.value;
      showSection();
    });
  });

  showSection();
};

export default ShowHideSection;
