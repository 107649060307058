import { getScrollTop } from "../util/lib";

const NavColourSwitcher = (el) => {
  let backgroundColourSectionMinMaxs = [];
  let raf;

  const pollScroll = (loop = true) => {
    const st = getScrollTop();
    const rect = el.getBoundingClientRect();

    for (let i = backgroundColourSectionMinMaxs.length - 1; i >= 0; i--) {
      const { min, colour, hoverColour } = backgroundColourSectionMinMaxs[i];
      if (min - st < rect.top + rect.height * 0.5) {
        el.style.setProperty("--color", colour);
        el.style.setProperty("--color-hover", hoverColour);
        break;
      }
    }

    if (loop) {
      raf = requestAnimationFrame(() => pollScroll());
    }
  };

  const onResize = () => {
    const st = getScrollTop();
    backgroundColourSectionMinMaxs = [
      ...document.querySelectorAll("[data-nav-colour-switcher-colour]"),
    ].map((el) => {
      const rect = el.getBoundingClientRect();
      const min = rect.top + st;
      const max = min + rect.height;
      const colour = el.dataset.navColourSwitcherColour;
      const hoverColour = el.dataset.navColourSwitcherColourHover;
      return { min, max, colour, hoverColour };
    });
  };

  const onInViewChange = (entries) => {
    if (entries[0].isIntersecting) {
      raf = requestAnimationFrame(() => pollScroll());
      onResize();
    } else {
      cancelAnimationFrame(raf);
    }
  };

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });
  inViewObserver.observe(el);

  onResize();
  window.addEventListener("resize", onResize);
};
export default NavColourSwitcher;
